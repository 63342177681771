var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"my-3"},[_vm._v("Треки компетенции")]),(_vm.currentTracks.length)?_c('div',[_c('v-simple-table',{staticClass:"mb-3",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"width":"100px"}},[_vm._v("ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Тип трека")]),_c('th',{staticClass:"text-left"},[_vm._v("Пенальти")]),_c('th',{staticClass:"text-left"},[_vm._v("Весовой коэф.")]),_c('th',{staticClass:"text-left"},[_vm._v("Максимальны балл")]),_c('th',{staticClass:"text-left"},[_vm._v("Главный трек")]),_c('th',{attrs:{"width":"120px"}})])]),_c('tbody',_vm._l((_vm.currentTracks),function(item){return _c('tr',{key:item.id,on:{"click":function($event){return _vm.$router.push({
                name: 'competence_track',
                params: { track_id: item.id },
              })}}},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(" "+_vm._s(item.track.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.penalty)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.weight)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.score_limit === null ? "Нет ограничений" : item.score_limit)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.main)+" ")]),_c('td',{staticClass:"text-right"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{attrs:{"color":hover ? 'primary' : 'primary lighten-1',"title":"Настроить трек","icon":"","to":{
                    name: 'competence_track',
                    params: { track_id: item.id },
                  }}},[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{attrs:{"color":hover ? 'error' : 'grey lighten-1',"title":"Удалить трек","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveTrack(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)})],1)])}),0)]},proxy:true}],null,false,2735521757)})],1):_c('p',[_vm._v("У этой компетенции еще нет треков.")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('onOpenAssignDialog')}}},[_vm._v("Добавить трек")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }