<template>
  <div>
    <h3 class="my-3">Треки компетенции</h3>
    <div v-if="currentTracks.length">
      <v-simple-table class="mb-3">
        <template #default>
          <thead>
            <tr>
              <th class="text-left" width="100px">ID</th>
              <th class="text-left">Тип трека</th>
              <th class="text-left">Пенальти</th>
              <th class="text-left">Весовой коэф.</th>
              <th class="text-left">Максимальны балл</th>
              <th class="text-left">Главный трек</th>
              <th width="120px"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in currentTracks"
              :key="item.id"
              @click="
                $router.push({
                  name: 'competence_track',
                  params: { track_id: item.id },
                })
              "
            >
              <td>{{ item.id }}</td>
              <td>
                {{ item.track.name }}
              </td>
              <td>
                {{ item.penalty }}
              </td>
              <td>
                {{ item.weight }}
              </td>
              <td>
                {{
                  item.score_limit === null
                    ? "Нет ограничений"
                    : item.score_limit
                }}
              </td>
              <td>
                {{ item.main }}
              </td>
              <td class="text-right">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    :color="hover ? 'primary' : 'primary lighten-1'"
                    title="Настроить трек"
                    icon
                    :to="{
                      name: 'competence_track',
                      params: { track_id: item.id },
                    }"
                  >
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </v-hover>
                <v-hover v-slot="{ hover }">
                  <v-btn
                    :color="hover ? 'error' : 'grey lighten-1'"
                    title="Удалить трек"
                    icon
                    @click.stop="handleRemoveTrack(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-hover>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <p v-else>У этой компетенции еще нет треков.</p>
    <v-btn color="primary" @click.prevent="$emit('onOpenAssignDialog')"
      >Добавить трек</v-btn
    >
  </div>
</template>

<script>
import { apiClient } from "@/api";

export default {
  name: "TracksList",
  props: {
    competence: {
      type: Object,
    },
    currentTracks: {
      type: Array,
    },
  },
  methods: {
    async handleRemoveTrack(compTrack) {
      const confirm = await this.$root.$confirm(
        "Удалить трек",
        `Вы уверены что хотите удалить трек <strong>&laquo;${compTrack.track.name}&raquo;</strong> из этой компетенции? Все настройки трека, так же будут удалены.`,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
          confirmColor: "error",
        }
      );
      if (confirm) {
        try {
          await apiClient({
            method: "DELETE",
            url: `/competences/${this.competence.id}/tracks/${compTrack.id}`,
          });
          this.$toast(`Трек ${compTrack.track.name} удален`, {
            type: "success",
          });
        } catch (error) {
          this.$toast(`Не удалось удалить трек. ${error.message}`, {
            type: "error",
          });
        }
      }
    },
  },
};
</script>

<style></style>
